<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-body">
          <div>
            <table
              role="table"
              aria-busy="false"
              aria-colcount="3"
              class="table b-table table-striped table-hover"
              id="__BVID__323"
            >
              <!----><!---->
              <thead role="rowgroup" class="">
                <!---->
                <tr role="row" class="">
                  <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="1"
                    class=""
                  >
                    <div>#</div>
                  </th>
                  <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="2"
                    class=""
                  >
                    <div>Department Name</div>
                  </th>
                  <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="3"
                    class=""
                  >
                    <div>Status</div>
                  </th>

                  <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="4"
                    class=""
                  >
                    <div>Action</div>
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <!---->
                <tr
                  role="row"
                  class=""
                  v-for="(dItem, index) in getDepartments"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ dItem.name }}</td>
                  <td>{{ dItem.status | status }}</td>
                  <td></td>
                </tr>

                <!----><!---->
              </tbody>
              <!---->
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { FETCH_DEPARTMENTS } from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getDepartments"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Departments" }]);
    this.$store.dispatch(FETCH_DEPARTMENTS);
  },
  filters: {
    status(status) {
      return status == 1 ? "Active" : "Inactive";
    }
  }
};
</script>
